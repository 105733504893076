import { calculatePaginationInfo } from 'helpers/pagination'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import * as ACTIONS from '../constants/action_types/product_costs'
import { LoadingStates } from '../constants/api'
import {
  addEffectiveToDate,
  getUpdatedProductCosts
} from '../helpers/product_cost'
import { getEndCount } from '../helpers/pagination'

export const INITIAL_STATE = {
  data: {
    loadingState: LoadingStates.initialLoad,
    pagination: {
      perPage: 20,
      startCount: 0,
      endCount: 0,
      totalCount: 0,
      currentPage: null,
      nextPage: null
    },
    enabled: true,
    search: '',
    orderDir: 'asc',
    orderBy: 'title',
    products: [],
    productCosts: {}
  },
  ui: {
    isModalOpen: false,
    selectedCost: {},
    selectedProduct: {},
    isSaving: false,
    isModalLoading: LoadingStates.initialLoad,
    isExportLoading: LoadingStates.initialLoad,
    importLoadingState: LoadingStates.initialLoad,
    importSuccess: null
  }
}

function productCosts(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTIONS.PRODUCT_COSTS_LOAD_PRODUCTS_PENDING_STATUS:
      return {
        ...state,
        data: {
          ...state.data,
          loadingState: action.payload.initialLoad
            ? LoadingStates.initialLoad
            : LoadingStates.loading
        }
      }
    case ACTIONS.PRODUCT_COSTS_LOAD_PRODUCTS_COMPLETE:
      const {
        next_page,
        current_page,
        total_count,
        products,
        goingForwards,
        resetPagination,
        search,
        orderBy,
        orderDir
      } = action.payload

      const paginationData = calculatePaginationInfo(
        products,
        next_page,
        goingForwards,
        state.data.pagination,
        'nextPage',
        resetPagination
      )

      return {
        ...state,
        data: {
          ...state.data,
          products,
          productCosts: products.reduce((acc, product) => {
            return { ...acc, [product.id]: product.product_costs }
          }, {}),
          pagination: {
            ...state.data.pagination,
            ...paginationData,
            nextPage: next_page,
            currentPage: current_page,
            endCount: getEndCount(
              current_page,
              state.data.pagination.perPage,
              products.length
            ),
            totalCount: Number(total_count) || 0 // sometimes we get back "0" or similar
          },
          search,
          orderBy,
          orderDir
        }
      }
    case ACTIONS.PRODUCT_COSTS_LOAD_PRODUCTS_COMPLETE_STATUS:
      return {
        ...state,
        data: {
          ...state.data,
          loadingState: LoadingStates.loaded
        }
      }
    case ACTIONS.PRODUCT_COSTS_LOAD_PER_PAGE_CHANGED:
      return {
        ...state,
        data: {
          ...state.data,
          pagination: {
            ...state.data.pagination,
            perPage: action.payload.perPage
          }
        }
      }
    case ACTIONS.SET_SELECTED_PRODUCT_COST_PENDING_STATUS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isModalLoading: LoadingStates.loading
        }
      }
    case ACTIONS.SET_SELECTED_PRODUCT_COST_COMPLETE:
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedCost: action.payload.selectedCost,
          selectedProduct: action.payload.productInfo
        }
      }
    case ACTIONS.SET_SELECTED_PRODUCT_COST_COMPLETE_STATUS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isModalLoading: LoadingStates.loaded
        }
      }
    case ACTIONS.SET_PRODUCT_COST_MODAL_IS_OPEN:
      return {
        ...state,
        ui: {
          ...state.ui,
          isModalOpen: action.payload.isOpen
        }
      }
    case ACTIONS.SET_IS_SAVING_PRODUCT_COST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isSaving: action.payload.isSaving
        }
      }
    case ACTIONS.RECEIVE_CREATE_PRODUCT_COST:
      const productDisplayCreate = pick(
        state.data.productCosts,
        action.payload.productCost.product_id
      )

      const newProductCosts = isEmpty(productDisplayCreate)
        ? {
            ...state.data.productCosts
          }
        : {
            ...state.data.productCosts,
            [action.payload.productCost.product_id]: addEffectiveToDate([
              ...state.data.productCosts[action.payload.productCost.product_id],
              action.payload.productCost
            ])
          }
      return {
        ...state,
        ui: {
          ...state.ui,
          isSaving: false
        },
        data: {
          ...state.data,
          productCosts: newProductCosts
        }
      }
    case ACTIONS.UPDATE_ACTIVE_PRODUCT_COST:
      return {
        ...state,
        data: {
          ...state.data,
          products: action.payload.products
        }
      }
    case ACTIONS.RECEIVE_UPDATE_PRODUCT_COST:
      // We only need to update the displayed product costs
      // IF the updated cost in being displayed in the current pagination
      // Otherwise keep the product cost list unchanged
      const productDisplayUpdate = pick(
        state.data.productCosts,
        action.payload.productCost.product_id
      )

      const productCosts = isEmpty(productDisplayUpdate)
        ? {
            ...state.data.productCosts
          }
        : {
            ...state.data.productCosts,
            [action.payload.productCost.product_id]: getUpdatedProductCosts(
              action.payload.productCost,
              state.data.productCosts[action.payload.productCost.product_id]
            )
          }
      return {
        ...state,
        data: {
          ...state.data,
          productCosts
        }
      }
    case ACTIONS.RECEIVE_PRODUCT_COST_DELETE:
      return {
        ...state,
        data: {
          ...state.data,
          productCosts: {
            ...state.data.productCosts,
            [action.payload.productId]: addEffectiveToDate(
              state.data.productCosts[action.payload.productId].filter(
                c => c.id !== action.payload.productCost.id
              )
            )
          }
        }
      }
    case ACTIONS.SET_PRODUCT_COST_ENABLED_FILTER:
      return {
        ...state,
        data: {
          ...state.data,
          enabled: action.payload.enabled
        }
      }
    case ACTIONS.PRODUCT_COSTS_LOAD_EXPORT_PENDING_STATUS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isExportLoading: LoadingStates.loading
        }
      }
    case ACTIONS.PRODUCT_COSTS_LOAD_EXPORT_COMPLETE_STATUS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isExportLoading: action.payload.success
            ? LoadingStates.loaded
            : LoadingStates.failed
        }
      }
    case ACTIONS.PRODUCT_COSTS_LOAD_IMPORT_PENDING_STATUS:
      return {
        ...state,
        ui: {
          ...state.ui,
          importLoadingState: LoadingStates.loading
        }
      }
    case ACTIONS.PRODUCT_COSTS_LOAD_IMPORT_COMPLETE:
      return {
        ...state,
        ui: {
          ...state.ui,
          importLoadingState: action.payload.success
            ? LoadingStates.loaded
            : LoadingStates.failed,
          importSuccess: action.payload.success
        }
      }
    case ACTIONS.PRODUCT_COSTS_LOAD_IMPORT_RESET:
      return {
        ...state,
        ui: {
          ...state.ui,
          importSuccess: null
        }
      }
    case ACTIONS.BULK_UPDATE_PRODUCT_COSTS: {
      const newProductsMap = action.payload.reduce((acc, curr) => {
        return { ...acc, [curr.id]: curr }
      }, {})

      const newProducts = state.data.products.map(product => {
        return newProductsMap[product.id] || product
      })

      return {
        ...state,
        data: {
          ...state.data,
          products: newProducts,
          productCosts: newProducts.reduce((acc, product) => {
            return { ...acc, [product.id]: product.product_costs }
          }, {})
        }
      }
    }
    case ACTIONS.RESET_PRODUCT_COSTS:
      return {
        ...INITIAL_STATE
      }
    default:
      return state
  }
}

export default productCosts
