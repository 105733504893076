import * as types from '../../constants/action_types/product_insights/asin_explorer'

export const initialState = {
  initialLoad: true,
  product: null,
  search: {
    isLoading: false
  },
  trackedProducts: {
    isLoading: false,
    sortOption: 'created_atDesc',
    products: []
  },
  pendingTracks: [],
  importCsv: {
    isLoading: false,
    error: null
  },
  trackVariants: {
    isLoading: false,
    isComplete: false
  }
}

function asinExplorer(state = initialState, action) {
  switch (action.type) {
    case types.PENDING_ASIN_EXPLORER_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    case types.RESET_ASIN_EXPLORER_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false
        }
      }
    case types.SET_ASIN_EXPLORER_DATA:
      return {
        ...state,
        ...action.payload
      }
    case types.SET_ASIN_EXPLORER_TRACKED_PRODUCTS: {
      const { products } = action.payload
      const trackedIds = products.map(({ id }) => id)
      const pendingTracks = state.pendingTracks.filter(
        id => !trackedIds.includes(id)
      )

      return {
        ...state,
        pendingTracks,
        trackedProducts: {
          ...state.trackedProducts,
          products
        }
      }
    }
    case types.APPEND_ASIN_EXPLORER_TRACKED_PRODUCTS: {
      const { products } = action.payload
      return {
        ...state,
        trackedProducts: {
          ...state.trackedProducts,
          products: [...state.trackedProducts.products, ...products]
        }
      }
    }
    case types.SET_ASIN_EXPLORER_SORTING:
      return {
        ...state,
        trackedProducts: {
          ...state.trackedProducts,
          sortOption: action.payload
        }
      }
    case types.COMPLETED_ASIN_EXPLORER_TRACK_ASIN: {
      const { id, product_track_id } = action.payload
      const products = [...state.trackedProducts.products]
      const index = products.findIndex(p => p.id === id)
      const pendingTracks = state.pendingTracks.filter(
        productId => productId !== id
      )

      if (index > -1) {
        products[index] = { ...products[index], product_track_id }
      } else {
        products.push(action.payload)
      }

      return {
        ...state,
        pendingTracks,
        trackedProducts: {
          ...state.trackedProducts,
          products
        }
      }
    }
    case types.COMPLETED_ASIN_EXPLORER_UNTRACK_ASIN: {
      const pendingTracks = state.pendingTracks.filter(
        id => id !== action.payload
      )

      return {
        ...state,
        pendingTracks
      }
    }
    case types.SET_ASIN_EXPLORER_PENDING_STATUS: {
      const { id: trackId, pending } = action.payload

      const pendingTracks = pending
        ? [...state.pendingTracks, trackId]
        : state.pendingTracks.filter(id => id !== trackId)

      return {
        ...state,
        pendingTracks
      }
    }
    case types.PENDING_ASIN_EXPLORER_TRACK_VARIANTS: {
      return {
        ...state,
        trackVariants: {
          ...state.trackVariants,
          isLoading: true,
          isComplete: false
        }
      }
    }
    case types.COMPLETED_ASIN_EXPLORER_TRACK_VARIANTS: {
      return {
        ...state,
        trackVariants: {
          ...state.trackVariants,
          isLoading: false,
          isComplete: true
        }
      }
    }
    case types.RESET_ASIN_EXPLORER_TRACK_VARIANTS: {
      return {
        ...state,
        trackVariants: {
          ...state.trackVariants,
          isLoading: false,
          isComplete: false
        }
      }
    }
    case types.PENDING_ASIN_EXPLORER_CSV_IMPORT: {
      return {
        ...state,
        importCsv: {
          ...state.importCsv,
          isLoading: true,
          error: null
        }
      }
    }
    case types.COMPLETED_ASIN_EXPLORER_CSV_IMPORT: {
      return {
        ...state,
        importCsv: {
          ...state.importCsv,
          isLoading: false,
          error: action.payload.error
        }
      }
    }
    default:
      return state
  }
}

export default asinExplorer
