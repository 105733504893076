import omit from 'lodash/omit'
import * as type from '../constants/action_types/product_alerts'

export const initialState = {
  alertedProducts: {},
  productAlertsFetch: {
    isLoading: false
  },
  batchOperationStatus: []
}

function productAlerts(state = initialState, action) {
  switch (action.type) {
    case type.PENDING_PRODUCT_ALERTS_ADD_REMOVE_ACTION: {
      const existingState = state.alertedProducts[action.payload] || {}
      return {
        ...state,
        alertedProducts: {
          ...state.alertedProducts,
          [action.payload]: {
            ...existingState,
            isLoading: true
          }
        }
      }
    }
    case type.PENDING_PRODUCT_ALERTS_ADD_REMOVE_BULK_ACTION: {
      return action.payload.reduce((updatedState, key) => {
        const existingState = updatedState.alertedProducts[key] || {}
        return {
          ...updatedState,
          alertedProducts: {
            ...updatedState.alertedProducts,
            [key]: {
              ...existingState,
              isLoading: true
            }
          }
        }
      }, state)
    }
    case type.COMPLETED_ADD_PRODUCT_ALERTS_ACTION: {
      return {
        ...state,
        alertedProducts: {
          ...state.alertedProducts,
          [action.payload.pipelines_id]: action.payload
        }
      }
    }
    case type.COMPLETED_ADD_PRODUCT_ALERTS_BULK_ACTION: {
      return {
        ...state,
        alertedProducts: action.payload.reduce(
          (acc, product) => {
            acc[product.pipelines_id] = product
            return acc
          },
          { ...state.alertedProducts }
        )
      }
    }
    case type.COMPLETED_REMOVE_PRODUCT_ALERTS_ACTION: {
      return {
        ...state,
        alertedProducts: omit(state.alertedProducts, action.payload)
      }
    }
    case type.RESET_PRODUCT_ALERTS_ADD_REMOVE_ACTION: {
      const existingState = state.alertedProducts[action.payload] || {}
      return {
        ...state,
        alertedProducts: {
          ...state.alertedProducts,
          [action.payload]: {
            ...existingState,
            isLoading: false
          }
        }
      }
    }
    case type.RESET_PRODUCT_ALERTS_ADD_REMOVE_BULK_ACTION: {
      return action.payload.reduce((updatedState, key) => {
        const existingState = updatedState.alertedProducts[key] || {}
        return {
          ...updatedState,
          alertedProducts: {
            ...updatedState.alertedProducts,
            [key]: {
              ...existingState,
              isLoading: false
            }
          }
        }
      }, state)
    }
    case type.PENDING_PRODUCT_ALERTS_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    case type.RESET_PRODUCT_ALERTS_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false
        }
      }
    case type.SET_PRODUCT_ALERTS_DATA:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default productAlerts
