import findIndex from 'lodash/findIndex'
import uniqBy from 'lodash/uniqBy'
import cloneDeep from 'lodash/cloneDeep'

import { calculatePaginationInfo, resetPagination } from 'helpers/pagination'
import * as PRODUCT_ACTIONS from '../constants/action_types/product'

export const INITIAL_STATE = {
  isLoading: false,
  isLoadingTable: false,
  nextPage: 1,
  searchInputField: '',
  hasNext: true,
  productItems: [],
  perPage: 20,
  startCount: 0,
  endCount: 0,
  totalCount: 0,
  currentPage: null,
  orderDir: 'desc',
  orderBy: 'last_synced_at',
  syncInProgress: false,
  amazonParams: {
    amazon_seller_account_id: null,
    marketplace_id: null
  },
  getResyncStatusInterval: null,
  enabled: null // t/f
}

/*
  Use this helper to rebuild an array with custom values, by updating an attribute of an element in the array
  or by replacing the element.

  Params
  array: the original array to be modified
  value: the new value that will be used to modify the array element. If you pass a simple value to this param,
            the helper will modify the original element to receive this new value on the ~prop~ attribute and will
            return the object. If you pass a function to this parameter, the helper will ignore the ~prop~ attribute
            and will pass the array element to the function and expects that the whole modified element is returned.
  prop: the prop that will be modified (OPTIONAL). This is used only if the value attribute is not a function
  id: the identififer that will be used to select the correct element to be modified (OPTIONAL). If you don't specify
      this, all the elements in the array will be modifiec.
*/
// TODO: move this to a helper
const rebuildArray = ({ array, prop = '', id = null, value }) => {
  const modifiedValues = array.map(item => {
    if (item.id === id || id === null) {
      if (typeof value === 'function') {
        return value(item)
      }
      item[prop] = value
    }
    return item
  })

  return modifiedValues
}

const rebuildProductItems = (state, index, product) => [
  ...state.productItems.slice(0, index),
  product,
  ...state.productItems.slice(index + 1)
]

const getProductIndex = (state, productId) =>
  findIndex(state.productItems, p => p.id === productId)

function products(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PRODUCT_ACTIONS.UPDATE_PRODUCT_SEARCH_TERM: {
      return {
        ...state,
        searchInputField: action.payload
      }
    }
    case PRODUCT_ACTIONS.PRODUCT_PENDING_ACTION: {
      return {
        ...state,
        [action.payload || 'isLoading']: true
      }
    }
    case PRODUCT_ACTIONS.PRODUCT_COMPLETED_ACTION: {
      return {
        ...state,
        [action.payload || 'isLoading']: false
      }
    }
    case PRODUCT_ACTIONS.COMPLETED_LOAD_PRODUCTS: {
      const {
        next_page,
        products,
        orderDir,
        orderBy,
        preserveList
      } = action.payload

      // sometimes if we do a search we get the same result as we already have :shrug: hence the uniq
      return {
        ...state,
        hasNext: !!next_page,
        productItems: preserveList
          ? uniqBy([...state.productItems, ...products], 'id')
          : products,
        orderDir,
        orderBy
      }
    }
    case PRODUCT_ACTIONS.PRODUCT_UPDATE_PAGINATION_DETAILS: {
      const {
        next_page,
        products,
        goingForward,
        total_count,
        amazonParams
      } = action.payload
      const paginationData = calculatePaginationInfo(
        products,
        next_page,
        goingForward,
        state,
        'nextPage'
      )
      return {
        ...state,
        amazonParams,
        hasNext: !!next_page,
        ...paginationData,
        totalCount: Number(total_count) || 0 // sometimes we get back "0" or similar
      }
    }
    case PRODUCT_ACTIONS.PRODUCT_RESET_PAGINATION_DETAILS: {
      const { next_page, products, total_count, amazonParams } = action.payload
      return {
        ...state,
        amazonParams,
        ...resetPagination(products, next_page, 'nextPage'),
        totalCount: Number(total_count) || 0 // sometimes we get back "0" or similar
      }
    }
    case PRODUCT_ACTIONS.ENABLE_PARENT_VARIANT_EDITABLE_FIELD: {
      return {
        ...state,
        productItems: rebuildArray({
          array: state.productItems,
          id: action.payload,
          value: product => {
            product.isEditing = true
            return product
          }
        })
      }
    }
    case PRODUCT_ACTIONS.ENABLE_PRODUCT_CHILD_EDITABLE_FIELD: {
      const { parentId, childId } = action.payload

      const parentIndex = getProductIndex(state, parentId)
      const parent = { ...state.productItems[parentIndex] }

      parent.children = rebuildArray({
        array: parent.children,
        id: childId,
        value: child => {
          child.isEditing = true
          return child
        }
      })

      return {
        ...state,
        productItems: rebuildProductItems(state, parentIndex, parent)
      }
    }
    case PRODUCT_ACTIONS.UPDATE_PARENT_VARIANT_TITLE: {
      return {
        ...state,
        productItems: rebuildArray({
          array: state.productItems,
          id: action.payload.id,
          prop: 'title',
          value: action.payload.value
        })
      }
    }
    case PRODUCT_ACTIONS.UPDATE_PRODUCT_CHILD_TITLE: {
      const { parentId, childId, value } = action.payload

      const parentIndex = getProductIndex(state, parentId)
      const parent = { ...state.productItems[parentIndex] }

      parent.children = rebuildArray({
        array: parent.children,
        prop: 'title',
        id: childId,
        value
      })

      return {
        ...state,
        productItems: rebuildProductItems(state, parentIndex, parent)
      }
    }
    case PRODUCT_ACTIONS.RESET_PRODUCTS: {
      return {
        ...INITIAL_STATE
      }
    }
    case PRODUCT_ACTIONS.UPDATE_PRODUCTS_RESYNC_STATUS_ACTION: {
      const { updateData } = action.payload
      const productItems = cloneDeep(state.productItems)
      const productsMap = productItems.reduce((pre, cur) => {
        return (cur.children || []).reduce(
          (cPre, cCur) => ({
            ...cPre,
            [cCur.id]: cCur
          }),
          {
            ...pre,
            [cur.id]: cur
          }
        )
      }, {})

      updateData.forEach(data => {
        if (!productsMap[data.product_id]) return
        const { sync_status, last_synced_at } = data
        productsMap[data.product_id].sync_status = sync_status
        productsMap[data.product_id].isResyncing = sync_status === 1
        productsMap[data.product_id].last_synced_at = last_synced_at
      })
      return {
        ...state,
        productItems
      }
    }
    case PRODUCT_ACTIONS.UPDATE_GET_RESYNC_STATUS_TIMER_ACTION: {
      if (state.getResyncStatusInterval) {
        clearInterval(state.getResyncStatusInterval)
      }
      return {
        ...state,
        getResyncStatusInterval: action.payload.timer
      }
    }
    default:
      return state
  }
}

export default products
